:root {
  --primary-color: #ccc;
  --primary-text-color: rgba(0, 0, 0, 0.7);
  --app-green: rgb(136, 190, 125);

  --app-theme-color-primary: #fd9b3d;
  --app-theme-color-secondary: #e3e2f4;
  --app-theme-color-tertiary: #caecec;
  --app-theme-color-four: #868ad4;
}

body {
  margin: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--primary-text-color) !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.app-form-input {
  width: 250px;
  min-height: 45px;
  border-radius: 12px;
  padding-left: 8px;
  margin: 2% 0;
  border: 2px solid rgba(0, 0, 0, 0.1);
  transition: 0.5s;
  outline: none;
}

input:focus-visible {
  border: 2px solid var(--app-theme-color-tertiary);
}

select:focus-visible {
  border: 2px solid var(--app-theme-color-tertiary);
}
